var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-target-select"},[_c('config-field',{attrs:{"title":_vm.$t('post_publish_variant_title'),"help-message":_vm.$t('post_publish_variant_help_message', [_vm.$store.state.boardsState.activeBoard.title])}},[_c('div',{staticClass:"button-wrapper mt-1 flex flex-col md:flex-row gap-2"},_vm._l((_vm.postSources),function(target,index){return _c('a-button',{key:index,staticClass:"btn-wrap",class:{ 'active': target.value === _vm.activeTab },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.changeTab(target.value)}}},[_vm._v(" "+_vm._s(target.label)+" ")])}),1)]),_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.activeTab === _vm.TargetTypeTab.Target)?_c('div',{key:"target"},[_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'targets',
            'prefix': 'post_',
            'validation': 'required',
            'options': _vm.targetOptions,
            'multiple': true,
            'disabled': _vm.disabled,
            'selectAll': true
          },
        }},on:{"input":_vm.handlePostTargetsInput}})],1):_vm._e(),(_vm.activeTab === _vm.TargetTypeTab.Origin)?_c('div',{key:"origin"},[_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'origin',
            'prefix': 'post_',
            'validation': 'required',
            'options': _vm.originOptions,
            'multiple': false,
            'disabled': _vm.disabled
          },
        }}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'targets',
            'prefix': 'post_with_origin_',
            'validation': 'required',
            'options': _vm.targetOptions,
            'multiple': true,
            'disabled': _vm.disabled
          },
        }}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }