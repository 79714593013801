








































import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import TimezonePicker from 'piramis-base-components/src/components/TimezonePicker/TimezonePicker.vue'
import { SetTimezonePayload } from 'piramis-base-components/src/components/TimezonePicker/types'

import { Component, Mixins, Prop, PropSync, VModel } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    TimezonePicker
  },
  methods: {
    moment,
  }
})
export default class PostTime extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() runTime!: string

  @PropSync('timezone') tz!: string

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  setTimezone(payload: SetTimezonePayload) {
    this.tz = payload.timezone

    payload.closeModal()
    payload.closeLoader()
  }

  get postDate():string {
    return this.runTime.split(' ')[0] ?? ''
  }

  set postDate(newDate:string) {
    this.updateDatetime(newDate, undefined)
  }

  get postTime():string {
    return this.runTime.split(' ')[1] ?? ''
  }

  set postTime(newTime:string) {
    this.updateDatetime(undefined, newTime)
  }

  updateDatetime(date: string | null = this.postDate, time = this.postTime):void {
    const postDate = date === null ? moment().add('1', 'd').format('YYYY-MM-DD') : date

    this.runTime = `${ postDate } ${ time }`
  }

  mounted(): void {
    if (this.runTime) {
      const splitRunTime = this.runTime.split(' ')

      this.postDate = splitRunTime[0]
      this.postTime = splitRunTime[1]
    }
  }
}
