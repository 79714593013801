


















































import TestStateMixin from '../mixins/TestStateMixin'
import TestStateInfoModal from './TestStateInfoModal.vue'
import { ChannelInfo, TestChannelStateCode } from "@/includes/types/Channel.types";
import { TestBoardStateCode } from "@/includes/types/Board.types";

import { atSignedLogin } from "piramis-base-components/src/shared/utils/tgLoginHelpers";

import { snakeCase } from 'lodash'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    TestStateInfoModal,
  },
  data() {
    return {
      snakeCase,
    }
  }
})
export default class PostBotState extends Mixins(TestStateMixin) {
  @Prop() currentChannels!: Array<number>

  importantBoardErrors = [
    TestBoardStateCode.UndefinedError,
    TestBoardStateCode.HandlerError,
    TestBoardStateCode.ApiError,
    TestBoardStateCode.CommunicationError,
  ]

  importantChannelErrors = [
    TestChannelStateCode.UndefinedError,
    TestChannelStateCode.ApiError,
    TestChannelStateCode.ChannelNotFoundError,
    TestChannelStateCode.ChannelPermissionSendMessageError,
  ]

  get channelsInvalidTestState(): Array<{ title: string, id: number, state: TestChannelStateCode }> {
    return this.boardChannels
      .filter((channel) => this.importantChannelErrors.includes(channel.test_state))
      .map((channel) => {
        return {
          title: this.getChannelFullTitle(channel.title, channel.username),
          id: channel.id,
          state: channel.test_state,
        }
      })
  }

  getChannelFullTitle(title: string, username: string | undefined) {
    let fullTitle = title

    if (username) {
      fullTitle += ` - ${ atSignedLogin(username) }`
    }

    return fullTitle
  }

  get boardTestState(): boolean {
    return this.importantBoardErrors.includes(this.$store.state.boardsState.activeBoard!.test_state)
  }

  get boardChannels(): Array<ChannelInfo> {
    if (this.$store.state.boardsState.activeBoard) {
      return this.$store.getters.activeBoardChannels.filter((target) => this.currentChannels.includes(target.id))
    }

    return []
  }
}
