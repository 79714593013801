


















































































import { InputSetups } from '../../mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'

enum TargetTypeTab {
  Target,
  Origin
}

@Component({
  data() {
    return {
      TargetTypeTab
    }
  },
  components: {
    ConfigField
  }
})
export default class PostTargetSelect extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {

  @PropSync('vmTargets',{ type: Array, default: () => [] }) targets !: Array<number>

  @PropSync('vmOrigin',{ type: Number, default: null }) origin !: number | null

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  activeTab = TargetTypeTab.Target

  get postSources(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('send_post_to_channel').toString(),
        value: TargetTypeTab.Target
      },
      {
        label: this.$t('forward_post_to_channel').toString(),
        value: TargetTypeTab.Origin
      }
    ]
  }

  get boardTargetOptions(): Array<SelectOptionData> {
    return this.$store.getters.boardTargetOptions
  }

  get originOptions(): Array<SelectOptionData> {
    return this.boardTargetOptions.filter((o) => !this.targets.includes(o.value))
  }

  get targetOptions() {
    if (this.origin) {
      return this.boardTargetOptions.filter((o) => o.value !== this.origin)
    } else {
      return this.boardTargetOptions
    }
  }

  changeTab(tab: TargetTypeTab) {
    if (tab !== this.activeTab) {
      this.activeTab = tab

      this.targets = []
      this.origin = null
    }
  }

  updateTargetsWithoutLinkedGroup(targets:Array<number>):void {
    if (targets.length) {
      this.$store.dispatch('updateTargetsWithoutLinkedGroup', targets)
    } else {
      this.$store.dispatch('updateTargetsWithoutLinkedGroup', null)
    }
  }

  handlePostTargetsInput(values: Array<SelectOptionData>) {
    this.updateTargetsWithoutLinkedGroup(values.map(v => v.value))
  }

  mounted(): void {
    if (this.origin) {
      this.activeTab = TargetTypeTab.Origin
    }
  }
}
